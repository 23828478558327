<template>
  <div id="app">
  <v-main>
    <v-sheet
height="100vh"
class="overflow-hidden"
style="position: relative;"
>
    <router-view></router-view>
    
    </v-sheet>
  </v-main>
  </div>
</template>
<script type="text/javascript">

  export default {
};
</script>
<style lang="scss">
.sidebar_user_name{
  margin-top:15px;
  text-align:center;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #F68D3A!important;
}
.v-toolbar__title{
  cursor:pointer;
}
.v-toolbar__content, .v-toolbar__extension{
  padding:4px 0px!important;
}
.v-list-item__title{
  font-size:15px;
  margin-left:10px;
  font-weight:400;
}

.v-breadcrumbs__item{
  color:black;
}
.v-breadcrumbs {
  background:#f3f3f3;
  border-bottom:1px solid #f2f2f2;
  padding: 10px 12px!important;
}
.container-xl, .container-lg, .container-md, .container-sm, .container{
  max-width:3000px!important;
}
.v-toolbar__title{
	padding-left:15px!important;
}
</style>
